import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCbIK7fGLluuZJ6Mr4gLl0OScwTA4LgpLo",
  authDomain: "canoepolo-capetown.firebaseapp.com",
  projectId: "canoepolo-capetown",
  storageBucket: "canoepolo-capetown.appspot.com",
  messagingSenderId: "953463601472",
  appId: "1:953463601472:web:31f05f58e20ed6d4ef62a5",
  measurementId: "G-7XBZF2PFN2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
